import fr from 'vuetify/lib/locale/fr';

export default {
  $vuetify: {
    ...fr,
  },
  catalog_access: "accès catalogue",
  home: "accueil",
  mandates: "mandats",
  reports: "rapports",
  sales: "ventes",
  campaign_marketing: "campagne marketing",
  automated_email_sms: "email & sms automatisé",
  shops: "boutiques",
  duplicate: "dupliquer",
  create_shop_group: "créer boutique groupe",
  manage_concessions: "gestion des concessions",
  list: "liste",
  manage_payments: "gérer les paiements",
  users: "utilisateurs",
  phone_order: "commande téléphonique",
  orders: "commandes",
  carts: "paniers",
  manufacturer_club: "club constructeurs",
  accounting: "comptabilité",
  billing_accounts: "comptes et mandats",
  sepa_samples: "prélèvements sepa",
  invoices_memos: "factures & avoirs",
  subscriptions: "abonnements",
  billing_conditions: "conditions de facturations",
  products: "produits",
  tax: "tax",
  statistics: "statistiques",
  commissions_deliveries: "commissions & livraisons",
  sepa_mandate: "mandat sepa",
  marketing: "marketing",
  automated_templates: "templates automatisés",
  price_alerts: "alertes prix",
  duplicate_advanced_promotions: "dupliquer promotions avancées",
  advanced_promotions_coupons: "promotions et coupons avancés",
  simple_promotions: "promotions simples",
  coupons: "coupons",
  dead_stock_promotion_config: "config promotions stock mort",
  parts_price_alerts: "alertes prix pièces",
  crm_email_sms: "crm email & sms",
  email_configuration: "configuration emails",
  sms_configuration: "configuration sms",
  conversational_sms: "sms conversationnel",
  pending_campaigns: "campagnes en attente",
  manual_campaign_management: "gestion campagne manuelle",
  manual_campaign_contacts: "contacts campagne manuelle",
  gdpr_unsubscription: "désinscription rgpd",
  stock_info: "info stock",
  interface: "interface",
  vn_vo_test: "test vn/vo",
  stop_mails_sms_test: "test stop mails/sms",
  mecaplanning: "mecaplanning",
  stock_test: "test stock",
  stock_mapping: "mapping stock",
  vn_vo_configuration: "configuration vn/vo",
  stop_mails_sms_configuration: "configuration stop mails/sms",
  stock_configuration: "configuration stock",
  stock_qualification: "qualification de stock",
  stock_qualification_report: "rapport de qualification du stock",
  mecaplanning_configuration: "configuration mecaplanning",
  interface_status: "etat des interfaces",
  interface_status_per_shop: "etat d'interface par boutique",
  site_content: "contenu site",
  menu: "menu",
  carousel: "carrousel",
  homepage_content: "contenu page d'acceuil",
  homepage_products: "produits page d'accueil",
  banner_model: "bannière modèle",
  footer: "footer",
  meta_configuration: "configuration meta",
  vehicle_display: "affichage vehicule",
  cms_page: "page cms",
  blog: "blog",
  family_page_content: "contenu page famille",
  file_management: "gestion fichiers",
  carrier: "transporteur",
  product_catalog: "catalogue produits",
  accessory_products: "produits accessoires",
  category_management: "gestion catégories",
  supplier_management: "gestion fournisseurs",
  supplier_model_mapping: "fournisseurs mapping modèle",
  category_mapping: "mapping des catégories",
  category_assignment_rules: "règles d'affectation categories",
  product_mapping: "mapping produit",
  scrapped_products: "produits scrappés",
  product_order: "ordre produits",
  unknown_products: "produits inconnus",
  specific_product_content: "contenu spécifique produit",
  tires: "pneumatiques",
  prices: "tarifs",
  priorities: "priorités",
  homepage_priority: "priorité page d'accueil",
  mounting: "montage",
  brands: "brands",
  benchmark: "benchmark",
  parts: "pièces",
  generic_products: "produits génériques",
  search_by_reference: "recherche par référence",
  my_shops: "mes boutiques",
  purchase_settings: "paramètre d'achat",
  suppliers: "fournisseurs",
  customer_type: "type de client",
  selling_price: "prix de vente",
  breakdown: "dépannage",
  purchase_breakdown: "achat dépannage",
  order_tracking: "suivi commandes",
  onboarding_form: "formulaire d'onboarding",
  fitting_time: "temps de main d'oeuvre",
  price_file_upload: "upload fichier prix",
  sales_report: "rapport ventes",
  group: "groupe",
  groups: "groupes",
  select_all: "tout sélectionner",
  others: "autres",
  all: "tous",
  until: "Jusqu'à",
  from: "des",
  turnover_includes_vat: "CA TTC",
  which_is_dead_stock: 'Dont stock Mort',
  number_of_orders: 'Nombres de Commandes',
  last_months: "derniers mois",
  by_group: "par groupe",
  by_make: "par marque",
  by_shop: "par boutique",
  global: "global",
  shop: "boutique",
  order_date: "date commande",
  client: "client",
  reference: "référence",
  quantity: "quantité",
  amount: "montant",
  includes_vat: "ttc",
  incl_amount: "montant ttc",
  excl_amount: "montant ht",
  promotion: "promotion",
  promotion_type: "type de promotion",
  marketing_report: "rapport marketing",
  shops_list: 'liste des boutiques',
  delete: 'supprimer',
  apply: 'appliquer',
  marketing_report_welcome: "Bienvenue dans votre rapport marketing",
  marketing_report_infos: "Retrouvez ici toutes les informations concernant les communications qui sont automatiquement envoyées à vos clients",
  marketing_report_tpl1: "Après la commande d'un véhicule (TPL 1)",
  marketing_report_tpl2: "Après la commande d'un véhicule AVEC un coupon de réduction (TPL 2)",
  marketing_report_tpl3: "Après la réception du véhicule par le client (TPL 3)",
  marketing_report_tpl4: "Avant un rendez-vous Atelier (TPL 4)",
  marketing_report_nb: "NB: Semaines complètes, du lundi 00h01 au dimanche 23h59 inclus, heure France métropole",
  view_templates: "Consulter les templates",
  no_data_to_display: "pas de données à afficher",
  close: "fermer",
  folder: "Dossier",
  folders: "Dossiers",
  imported_folders: "Dossiers importés",
  folders_vn_vo: "Nombre de dossiers VN & VO nous parvenant",
  email: "Email",
  sms: "Sms",
  deliverability_of_automatic_communications: "Délivrabilité des communications automatiques",
  marketing_report_desc1: "La délivrabilité d’une communication est le fait que cette communication envoyée atteigne la boîte de réception de son destinataire, ce qui garantit le succès de vos campagnes.",
  marketing_report_desc2: "Pour calculer le taux de délivrabilité de vos campagnes, il suffit d' la formule suivante",
  marketing_report_desc3: "(nombre de communications délivrées / nombre total d'envois) x 100.",
  marketing_report_desc4: "La délivrabilité dépend donc de plusieurs facteurs",
  marketing_report_desc5: "La qualité du contenu : L'envoi de contenu indésirable ou non pertinent pour votre audience présente le risque d'être signalé comme spam, nuisant ainsi à la réputation de votre expéditeur. Évitez cela en envoyant du contenu de qualité et pertinent qui ne sera pas considéré comme du spam. Et en fournissant un contenu utile à votre audience, votre taux de désabonnement restera faible, ce qui est un autre facteur important !",
  marketing_report_desc6: "La qualité de votre liste de contacts : si certaines adresses n’existent pas ou sont erronées",
  marketing_report_desc7: "La réaction de vos destinataires : votre taux d’ouverture est-il bon ou au contraire, vos utilisateurs ont-ils tendance à se désinscrire ou à déclarer vos emails en spam voir ne pas cliquer ou ouvrir vos emails ?",
  marketing_report_desc8: "La délivrabilité de vos emails est fondamentale pour le succès de vos campagnes emailing.",
  marketing_report_desc9: "Comment susciter l’intérêt de vos clients et prospects et provoquer un achat s’ils ne reçoivent jamais vos emails marketing ?",
  marketing_report_desc10: "Au-delà de la délivrabilité, il est primordial de susciter l’engagement de vos clients/prospects en créant des offres intéressantes et pertinentes que vos destinataires ont envie de recevoir.",
  marketing_report_desc11: "Avec le temps, vos listes de contacts évoluent. Certains destinataires ne veulent plus recevoir d’emails marketing, d’autres ont supprimé ou n’utilisent plus leur adresse email.",
  marketing_report_desc12: "Pour assurer la bonne délivrabilité de vos emails, il est donc essentiel de mettre régulièrement à jour vos bases de données.",
  marketing_report_desc13: "Gérez les HardBounces en triant les adresses inactives ou mails non existant.",
  marketing_report_desc14: "Supprimez les destinataires qui n’ont pas ouvert vos emails ou cliqué sur un lien durant les 2 dernières années.",
  marketing_report_desc15: "Nombre d'envois des EMAILS automatisés",
  marketing_report_desc16: "Nombre d’emails automatisés TPL 1 / TPL 2 / TPL 3 ou bien TPL4 envoyés à vos clients",
  marketing_report_desc17: "Nombre d'envois des SMS automatisés",
  marketing_report_desc18: "Nombre de SMS automatisés TPL 1 / TPL 2 / TPL 3 ou bien TPL4 envoyés à vos clients",
  marketing_report_desc19: "Ce calcul vous donne un pourcentage qui correspond au taux d’ouverture de chaque communication",
  marketing_report_desc20: "(Nombre d’ouvertures de l’email / nombre d’emails délivrés) *100",
  marketing_report_desc21: "Prenons un exemple : Nous avons envoyé une newsletter à 4 600 contacts et 1 600 l’ont ouvert, le taux d’ouverture sera donc de 34,78 %",
  marketing_report_desc22: "L’analyse du taux d’ouverture vous permet d’évaluer la pertinence de votre l’offre ou sujet présenté ainsi que l’engagement suscité chez ces clients contactés.",
  marketing_report_desc23: "Le taux de clic est un indicateur de performance qui correspond à la proportion de destinataires ayant cliqué sur au moins un lien dans l’email. Pour le calculer",
  marketing_report_desc24: "Taux de clic = (nombre de clics sur un lien dans un email / nombre d’emails délivrés) x 100",
  marketing_report_desc25: "Imaginons que nous ayons délivré un email à 4 600 contacts. Parmi eux, 300 cliquent sur un des liens dans l’email. Dans ce cas, le taux de clic de cette campagne est de 6,52 %",
  marketing_report_desc25_2: "Le taux de clic est l’un des indicateurs de performance à surveiller en priorité pour évaluer l’efficacité d’une campagne emailing, le taux d’ouverture d’un e-mailing montre combien de destinataires pensent que l'email mérite leur attention. Le taux de clic va plus loin car il reflète (positivement ou négativement) la pertinence et la qualité du message et sa propension à inciter les destinataires à passer à l’action.",
  marketing_report_desc26: "Un taux de clic élevé signifie que le contenu de l’email fait écho aux problématiques de vos clients-cibles.",
  marketing_report_desc27: "Taux d'ouverture des mails",
  marketing_report_desc28: "Taux de clic des mails",
  all_data: "Toutes les données",
  this_month: "Ce mois-ci(M0)",
  last_month: "Le mois dernier(M-1)",
  last_three_month: "Les trois derniers mois(M0 + M-1 + M-2)",
  daily: "Quotidien",
  weekly: "Hebdomadaire",
  monthly: "Mensuel",
  marketing_campaign_report: "Rapport campagne marketing",
  name: "Nom",
  sin_status: "Status SIB",
  sender: "Expéditeur",
  send_date:  "Date d'envoi",
  contacts: "Contacts",
  approved: "Approuvé",
  opened: "Ouverture",
  open: 'Consulter',
  view: 'Voir',
  click: "Click",
  unsubscribes: "Déinscriptions",
  rate: "Taux",
  search: "recherche",
  months: "mois",
  filter: "filtre",
  filters: "filtres",
  billing_account: "Compte de facturation",
  delivery: "livraison",
  invoices: "factures",
  List_of_articles: "listes des articles",
  payment: "Paiement",
  payments: "Paiements",
  search_by_reference_order_id_customer_name_or_first_name: "Recherche par référence, id commande, nom ou prénom du client",
  all_status: "Tout Status",
  unprocessed_oders: "Commandes non traité",
  in_preparation: "En cours de préparation",
  ready_to_ship: "Prêt pour l'expédition",
  shipped: "Expédié",
  delivered: "Livré",
  available_for_pickup_in_dealership: "Disponible pour retrait en concession",
  Cancelled: "Annulé",
  refunded: "Remboursé",
  awaiting_restocking: "En attente de réapprovisionnement",
  paid: "payé",
  all_prices: "Tous Prix",
  price_over_250: "Prix supérieur à 250€",
  price_over_500: "Prix supérieur à 500€",
  all_makes: "Toutes les Marques",
  total: "total",
  state: 'etat',
  date: 'date',
  action: 'action',
  transfers: 'virements',
  description: 'description',
  stripe_reference: 'Reférence stripe',
  payment_by_order: 'Paiement par commande',
  refund_by_order: 'Remboursement par commande',
  execution_date: 'Date d\'exécution',
  invoice_number: "N° Facture",
  total_customer_order: "Total commande client",
  total_in_bank: "Total en banque",
  transfer_id: "Id virement",
  company_name: "Nom société",
  iban: "IBAN",
  stripe_mangopay_account: "Compte Stripe/Manpogay",
  commission: "Commission",
  payment_sepa: "Paiement SEPA",
  payment_commission_restraint: "Paiement Commission Retenue",
  purchase: "achat",
  one_bill_at_the_end_of_the_month: "Une seule facture la fin du mois",
  invoice_per_order: "Facture par commande",
  do_not_charge : "Ne pas facturer",
  invoices_memos_list : "Liste des Factures/Avoirs",
  invoice_blocking_date : "Date blocage de facture",
  account_number: "N° compte",
  number: "N°",
  due_date: "échéance",
  billed_to: "Facturé à",
  order: "Commande",
  purchase_order_number: "N° bon Commande",
  product: "produit",
  search_by_number: "Recherche par Numéro",
  min_date: "Date min",
  max_date: "Date max",
  document: "document",
  documents: "Documents",
  commission_per_order: "Commission par commande",
  refund_of_commissions: "Remboursement des commissions",
  creation_date: "Date de création",
  make: "marque",
  alert_price_desc1: "Voici la liste des articles pour lesquels nous avons reçu le plus de demandes de clients, classés par priorité.",
  alert_price_desc2: 'En mettant une promotion, un message leur sera automatiquement envoyé et vous multipliez les opportunités de ventes.',
  promotion_currently_applied: "Promotion en cours d'application",
  promote: "Promotionner",
  add_promotion: "Ajouter une promotion",
  shops_concerned: "Boutique concernées",
  create_the_promotion: "Créer la promotion",
  start_date: "Date de début",
  end_date: "Date max",
  amount_of_the_reduction: "Montant de la réduction",
  product_name: "Nom du produit",
  unit_value: "Valeur unitaire",
  number_of_requests: "Nombre de demandes",
  total_requests: "Total des demandes",
  products_from_partner_dealerships: "Les produits des concessions partenaires",
  search_for_your_reference_in_troubleshooting: "Recherche de votre référence en dépannage",
  products_from_partner_dealerships_desc1: "Votre prix et le stock de cette référence a été vérifié il y a moins de 24h.",
  products_from_partner_dealerships_desc2: "Cela signifie que le stock et le prix votre référence n'a pas été vérifié depuis plus d'une journée.",
  24_72: "48/72h",
  order_now: "Commander",
  contact_us: "Nous Contacter",
  product_added_successfully: "Produit ajouté avec succés",
  problem_adding_product_to_cart: "Problème d'ajout du produit au panier",
  must_enter_your_first_and_last_name: "Il faut entrez votre nom et prénom",
  click_here: "cliquez ici",
  stock: "stock",
  seller_dpt: "DPT VENDEUR",
  fiability: "FIABILITE",
  price_includes_vat: "PRIX TTC",
  delivery_time: "Délai de livraison",
  accessory: "Accessoire",
  part: "Pièce",
  accessories: "Accessoires",
  colissimo_tracking: "Suivi Colissimo",
  see_details: "Voir detail",
  cancel: "Annuler",
  purchaser: "Acheteur",
  total_price: "Prix TOTAL",
  promotions: "promotions",
  discount: "remise",
  to: "au",
  promotion_add: "Ajouter une promotion",
  type: "Type",
  promotion_for_tyres: "Promotion pour les Pneus",
  promotion_for_parts: "Promotion pour les Pièces",
  promotion_for_accessories: "Promotion pour les Accessoires",
  choose: "choisir",
  concessions: "Concessions",
  families: "Familles",
  offer: "Offre",
  dates: "Dates",
  saving: "Enregistrement",
  next: "suivant",
  your_query_runs_correctly_and_finds: 'Votre requête tourne correctement et trouve',
  products_by_shop: "produits par boutique",
  example: "Exemple",
  loading: "Chargement",
  test_query: "Tester la requête",
  text_tag: 'Tag texte',
  or_else: "ou bien",
  coupon_url: "URL coupon",
  load_pdf_coupon: "Charger PDF coupon",
  image_tag: 'Tag image',
  load_image: "Charger image",
  info_part: "INFO PIÈCE",
  successfully_saved: "Enregistré avec succés",
  return_to_list: "Revenir à la liste",
  Level_error: "Erreur niveau",
  you_have_a_missing_condition: "vous avez une condition Manquante",
  you_have_a_missing_operator: "vous avez un Opérateur Manquant",
  you_have_a_Missing_Value_for_a_condition: "vous avez une Valeur Manquante pour une condition",
  generic_element: "Elèment Générique",
  promo_code: "Code Promo",
  family: "Famille",
  sub_family: "Sous Famille",
  sub_sub_family: "Sous sous Famille",
  with_promotion: "Avec promotion",
  contains: "Contient",
  does_not_contain: "Ne contient pas",
  starts_with: "Commence par",
  ends_with: "Se termine par",
  Is: "Est",
  equal_to: "Egal à",
  add_a_group: "Ajouter un group",
  attribute: "attribut",
  condition: "condition",
  add_a_condition: "Ajouter une condition",
  a_group_of_conditions_must_have_at_least_two_children : "Un Groupe de condition doit avoir au moin deux enfant",
  error_you_must_add_at_least_one_condition_or_condition_group: "Erreur: vous devez rajouter au moin une condition / groupe de condition",
  the_name_field_is_required: "Le champ nom est obligatoire",
  you_must_choose_at_least_one_concession: "Il faut Choisir au moins une conccession",
  you_must_test_your_request: "Il faut tester votre requête !",
  your_request_test_must_pass: "Il faut que le test de votre requête réussi !",
  the_request_must_return_at_least_one_product: "Il faut que la requête retourne au moin un produit !",
  you_must_specify_the_promotion_amount: "Il faut précisier le montant de la promotion !",
  you_must_specify_the_start_date: "Il faut précisier la date de début !",
  you_must_specify_the_end_date: "Il faut précisier la date de fin !",
  unexpected_error_contact: "Erreur inattandue. Contacter",
  operator: "Opérateur",
  category: "Catégorie",
  model: "Modèle",
  less_than: "Inférieur à",
  more_than: "Supérieur à",
  among_the_list: "Parmi la liste",
  you_cannot_modify_the_request_for_an_already_created_promotion: "Vous ne pouvez pas modifier la requête pour la promo déjà crée",
  price: "prix",
  limit_promotions_to_products_in_stock: "Limiter les promotions aux produits en stock",
  mounting_discount: "Remise montage",
  info_tyre: "INFO Pneu",
  free_mounting: "Montage gratuit",
  attention_due_to_this_change_carousels_related_to_this_promotion_will_only_display_for_shops_configured_here: "Attention ! suite à cette modification, les carrousels liés à cette promotion s'afficheront uniquement pour les boutiques configurées ici. !",
  you_must_specify_the_discount_value: "Il faut précisier la valeur de la remise !",
  you_must_specify_the_mounting_discount_value: "Il faut précisier la valeur de la remise montage !",
  you_must_fill_in_the_tag_text_field: "Il faut remplir le champ du Tag texte",
  you_must_provide_an_image: "Il faut mettre une image",
  you_must_provide_a_coupon: "Il faut fournir un coupon",
  you_must_provide_a_valid_url: "Il faut fournir une URL valide",
  an_error_occurred_while_loading_the_pdf_please_try_again: "Une erreur est survenue lors du chargement du pdf. Veuillez réessayer SVP",
  an_error_occurred_while_loading_the_image_please_try_again: "Une erreur est survenue lors du chargement de l'image. Veuillez réessayer SVP",
  the_dimension_you_are_looking_for: "La dimension que vous cherchez",
  greater_than_or_equal_to: "Supérieur ou égal à",
  less_than_or_equal_to: "Inférieur ou égal à",
  summer: "Ete",
  winter: "Hiver",
  four_seasons: "4 Saisons",
  price_excluding_tax: "Prix HT",
  product_reference: "Référence du produit",
  ean_reference: "Référence EAN",
  manufacturer: "Manufacturier",
  diameter: "Diamètre",
  season: "Saison",
  approval: "Homologation",
  dimensions: "Dimensions",
  non_uniform_setting: "Paramétrage non homogène",
  save: "Sauvegarder",
  premium: "Premium",
  quality_price: "Qualité Prix",
  budget: "Budget",
  all_shops: "Toutes les boutiques",
  mounting_services: "Prestations de montage",
  different_sources: "Sources différentes",
  enter_prices_in_spid_cockpit: "Renseigner les prix dans SPID Cockpit",
  use_services_configured_in_quotation_tool_wyz: "Utiliser les services configurés dans l'outil de devis WYZ",
  some_shops_have_this_service_active: "Certaines boutiques ont ce service actif",
  some_shops_have_higher_amount: "Certaines boutiques ont un montant plus supérieur",
  shop_information: "Information Boutique",
  not_defined_yet: "Pas encore Définit",
  billing_mode: "Mode de facturation",
  stripe_account: "Compte Stripe",
  link_to_stripe: "Lien vers Stripe",
  assign_group: "Assigner Groupe",
  define_billing_account: "Définir Compte de facturation",
  inactive: "Inactif",
  active: "actif",
  status: "statut",
  domain_ssl: "domain_ssl",
  billing_acc: "Compte de fact",
  pause_my_ecommerce_sales: "Mettre en pause mes ventes e-commerce",
  activate_now: "Activer maintenant",
  help: "Aide",
  please_enter_your_email_we_will_contact_you_to_change_your_password: "Veuillez renseigner votre email, nous allons vous contacter afin de vous permettre de changer votre mot de passe",
  forgot_password: "Mot de passe oublié",
  an_email_has_been_sent_to: "Un email vient d'être envoyé à",
  check_your_spam_folder: "Vérifiez vos spams",
  enter_your_new_password: "Entrez votre nouveau mot de passe",
  login: "Se connecter",
  send: "Envoyer",
  last_three_month_2: "Les 3 derniers mois",
  last_six_month: "Les 6 derniers mois",
  all_history: "Tout l'historique",
  order_warn_1 : "Cette commande n'est pas encore virée sur votre compte bancaire. Elle le sera lorsque vous indiquerez que la pièce est disponible pour retrait en concession.",
  order_warn_2: "Cette commande a été annulée. Nous avons procédé au remboursement du Client.",
  order_warn_3: "Pas de bordereau sur cette commande.",
  order_warn_4: "Cette commande a été partiellement remboursée. Nous avons procédé au remboursement du Client.",
  order_warn_5: "une demande de retour a été effectuée pour cette commande.",
  order_warn_6: "une demande d'échange a été effectuée pour cette commande.",
  order_warn_7: "validation de demande d'échange en attente pour cette commande.",
  order_warn_8: "Demande d'échange en attente de validation pour cette commande.",
  order_warn_9: "validation de demande de Retour en attente pour cette commande.",
  order_warn_10: "Demande de Retour en attente de validation pour cette commande.",
  order_warn_11: "Cette commande n'est pas encore virée sur votre compte bancaire. Elle le sera lorsqu'elle sera scannée par La Poste.",
  order_warn_12: "Cette commande n'est pas encore virée sur votre compte bancaire. Elle le sera lorsque vous chargerez la facture au client final.",
  order_warn_13: "Alertez-nous via le Chat dès réception du colis afin que nous puissions rembourser le Client ou valider l’échange du produit.",
  order_warn_14: "",
  cancel_request: "Annuler la demande",
  do_you_confirm: "voulez vous confirmer ?",
  confirm: "Confirmer",
  old_request_will_cancel: "Les anciens demandes vont etre annuléés",
  colissimo_return_label_was_generated: "Un bordereau de retour a été généré pour cette commande",
  colissimo_return_label_was_sent: "Une demande de retour a été envoyée pour cette commande",
  parcel_well_received: "Colis reçus en bon état",
  by: 'par',
  for: 'de',
  pickup_address: "Adresse du point relais",
  delivery_address: "Adresse du livraison",
  cart_shop: "Boutique de panier",
  purchase_shop: "Boutique d'achat",
  delivery_method: "Méthode de livraison",
  collection_site: "Site de collecte",
  of_which_x_paid_in_addition: "Dont {x} {device} payé en complément",
  refunded_x: "{x} {device} remboursé",
  delivery_fee: "Frais de livraison",
  serenity_option: "Option Sérénité",
  tracking_url: "url de suivi",
  tracking: "Suivi",
  date_of_mounting_appointment:"Date du RDV de montage",
  print: 'Imprimer',
  add_a_note: "Ajouter une note",
  write_a_note: "Ecrire une note ?",
  catalog_price: "Prix catalogue",
  purchase_price: "Prix d'achat",
  expected_delivery_date : "Date de livraison prévue",
  article: "Article",
  assignment: "Affectation",
  return_exchange: "RETOUR/ECHANGE",
  product_modification: "Modification Produits",
  manage_parcel_label: "Gestion <br/> Bordereau colissimo",
  upload_parcel_label: "Uploader <br>le bordereau colissimo",
  upload_parcel_label_2: "Uploader le bordereau colissimo",
  manage_parcel_labels: "Gestion des bordereaux",
  a_problem:        "Un problème ?",
  report_a_problem: "Signalez un problème",
  messaging: 'Messagerie',
  new_message: "Nouveau Message",
  last_message: "Dernier message",
  link: "lien",
  no_history: "Pas d'historique",
  are_products_available: "Est ce que les produits sont disponibles ?",
  availability: "Disponibilité",
  expedition: 'Expédition',
  billing: 'Facturation',
  yes: 'Oui',
  no: 'No',
  no_i_have_to_order_some: "Non je dois commander certains produits",
  wyz_order_code: "Code Commande WYZ",
  product_availability_date: "La date de disponibilité des articles ?",
  edit: 'Modifier',
  save_dates: "Enregistrer les dates",
  order_stepper_message_1:"Le client souhaite passer chercher les articles en concession",
  order_stepper_message_2:"Veuillez valider cette étape lorsque la commande est disponible au comptoir. Le client reçevra un message automatiquement et vouz serez réglé.",
  order_stepper_message_3: "Le colis est {order_state} et vous pouvez maintenant facturer le client",
  customer_pickup_date: 'Date de retrait du client',
  optional: 'optionnel',
  excellent: "Excellent !",
  invoicing_address: 'Adresse de facturation',
  intern_invoice_number: "N° facture Interne",
  billing_details: 'Détail à facturer',
  mounting_fee: "Frais de montage",
  vat_excl: "HT",
  vat_incl: "TTC",
  bill_to: 'Facturer à {email}',
  you_can_see_regulations_deatil_below: "Vous pouvez voir les détails des réglements ci dessous",
  additional_information: "Information complémentaire",
  for_this_order_commission_is: "Pour cette commande notre commission est",
  SPID_commission:"Commission SPID",
  history: 'Historique',
  supplements: 'Supplements',
  supplier_activation_manage_by_quality: 'Activation Fournisseur par qualité',
}