<template>
  <v-dialog v-model="dialog" max-width="400">
    <v-card>
      <v-card-text class="pa-3">
        Bonjour,<br>
        Vous n'avez pas de mandat Sepa valide pour votre compte. <strong>Votre site e-commerce est désactivé.</strong><br><br>
        <strong>Vous devez donc signer le mandat Spid-Tech</strong> pour les commissions dues sur les ventes d'accessoires d'origine.<br>
        Le mandat WYZ Accessories pour l'achat d'accessoires aftermarket en dépannage est optionnel.<br>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="closePopup" > Plus tard </v-btn>
        <v-btn color="primary" text @click="navigate()" > J'y vais </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BILLING_ACCOUNTS from "@/graphql/billing/billing-accounts.gql";

export default {
  data () {
    return {
      dialog: false,
      interval: 0,
      mustAlert: false,
      billingAccounts: []
    }
  },
  methods: {
    closePopup () {
      this.dialog = false;
      clearInterval(this.interval);
    },
    navigate() {
      this.dialog = false;
      clearInterval(this.interval);
      this.$router.push('/billing-account')
    }
  },
  mounted () {
    if (!this.$auth.ensure('MANAGE_HBS_MANDATE')) {
      return;
    }
    this.$apollo.query({
      query: BILLING_ACCOUNTS
    }).then(res => {
      this.billingAccounts = res.data.billingAccounts;
    })
    this.interval = setInterval(function () {
      this.mustAlert = this.billingAccounts.find(b => b.mustAlert)

      if (this.mustAlert) {
        this.dialog = true;
      }
    }.bind(this), 6000)

  }
}
</script>